import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/aerial-tour.css"


function AerialTour () {


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content ministries-colors aerial-tour">
			<div className="subheader">
					   <h1 className="name"> 
					   St. John's Campus
			       </h1>
			       <h2> 
			        
			       </h2>
			</div>
			<div className="video">
				<iframe src="https://player.vimeo.com/video/444723242" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
			</div>
			<div className="panorama">
				<h2>Explore 360° Photo</h2>
				<iframe src="https://momento360.com/e/u/9b5994715d7347fa93de5b75f963e374?utm_campaign=embed&utm_source=other&heading=0&pitch=-20&field-of-view=75" />
			</div>

			</main>
			<Footer />		
		</div>
		)
}

export default AerialTour 
